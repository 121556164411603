<template>
  <div class="video-container">
    <div class="video-container__panel">
      <div class="video-container-panel">
        <img
            style="height: 100%"
            :src="banner"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "BannerWrapper",

  computed: {
    ...mapGetters({
      banners: 'GET_BANNERS',
    }),

    banner() {
      if (this.banners.length > 0) {
        return this.banners[this.current]
      }
      return ''
    },
  },

  mounted() {
    if (this.banners.length > 1) {
      this.startTimer()
    }
  },

  beforeDestroy() {
    this.timer = null
  },

  watch: {
    currentTime(n) {
      if (n === -1) {
        this.stopTimer();
        this.currentTime = this.defaultTime
        if (this.current === this.banners.length - 1) {
          this.current = 0
        } else {
          this.current++
        }
        this.startTimer();
      }
    }
  },

  data() {
    return {
      current: 0,
      timer: null,
      defaultTime: 60,
      currentTime: 60,
    }
  },

  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime--
      }, 1000)
    },

    stopTimer() {
      clearTimeout(this.timer)
    },
  }
}
</script>

<style lang="scss">
.video-container-panel {
  img {
    width: 100%;
    height: 1315px;
    object-fit: cover;
    object-position: top;
  }
}
</style>
